import Vue from "vue"
import CreateUser from './widgets/widget-user-create'
import UserInfo from './widgets/widget-user-detail'
import Loading from '@/components/widgets/Loading.vue'
import SortedTablePlugin from "vue-sorted-table"
import Paginate from "@/components/widgets/pagination.vue";

Vue.use(SortedTablePlugin, {
  ascIcon: '<i class="mdi mdi-arrow-down"></i>',
  descIcon: '<i class="mdi mdi-arrow-up"></i>'
})

export default {
  components: {
    Loading,
    CreateUser,
    UserInfo,
    Paginate
  },
  data: function() {
    return {
      role: "",
      loading: false,
      busy: false,
      totalItem: 0,
      apiStatus: 'ไม่พบข้อมูล',
      keyword: '',
      create: {
        mode: 'create',
        Id: null,
        opened: false,
      },
      info: {
        Id: null,
        opened: false,
      },
      sortBy: 'name',
      perPage: 8,
      currentPage: 1,
      currentData: {},
      sortDesc: false,
      position: null,
      keyword_branch: null,
      branches: [],
      fields: [
        { 
          key: 'fullname', 
          label: 'ข้อมูล',
          thClass: 'font-weight-bold text-xs text-xs-1/2', 
          tdClass: 'text-xs-1/2' 
        },
        { 
          key: 'position', 
          label: 'ตำแหน่งงาน', 
          sortable: true, 
          thClass: 'font-weight-bold text-xs text-xs-1/2', 
          tdClass: 'text-gray-400 text-xs-1/2' 
        },
        { 
          key: 'detail.name', 
          label: 'สาขา', 
          sortable: true, 
          thClass: 'font-weight-bold text-xs text-xs-1/2', 
          tdClass: 'text-gray-400 text-xs-1/2' 
        },
        { 
          key: 'username', 
          label: 'ชื่อผู้ใช้งาน', 
          sortable: true, 
          thClass: 'font-weight-bold text-xs text-xs-1/2', 
          tdClass: 'text-gray-400 text-xs-1/2' 
        },
        { 
          key: 'created_at', 
          label: 'วันที่สร้าง', 
          sortable: true, 
          thClass: 'font-weight-bold text-xs text-xs-1/2', 
          tdClass: 'text-gray-400 text-xs-1/2' 
        },
        { 
          key: 'action', 
          label: '#', 
          class: 'text-right', 
          thClass: 'font-weight-bold text-xs-1/2', 
          tdClass: 'text-right text-xs-1/2' 
        }
      ],
      items: []
    }
  },
  computed: {
    rows() {
      return this.items.length
    }
  },
  methods: {
    async getRole(){
      const res = await this.$store.dispatch('Auth/getRole', {}, { root: true })
      this.role = res.data.role
      this.branch = res.data.branch_id
    },
    async getBranches() {
      // const result = await this.$store.dispatch('Branches/getAll', null, { root: true })
      // this.branches = result.data.data

      const result = await this.$axios.get(this.$host + "/api/v1/branches", {
        headers: {
        },
      })
      this.branches = result.data
    },
    oncreate() {
      this.create.mode = 'create'
      this.create.opened = !this.create.opened
    },
    onupdate(id) {
      this.create.Id = id
      this.create.mode = 'update'
      this.create.opened = !this.create.opened
    },
    onInfo(id) {
      this.info.Id = id
      this.info.opened = !this.info.opened
    },
    async onInitUsers(currentPage) {
      this.currentPage = currentPage;
      this.busy = true;
      this.apiStatus = "กำลังค้นหาข้อมูล..";
      this.items = [];

      try {
        const page = currentPage ? currentPage : 1
        const url = `${Vue.prototype.$host}/api/v1/user?page=${page}&perPage=8&keyword=${this.keyword}&role=${this.position ? this.position : ''}&keyword_branch=${this.keyword_branch ? this.keyword_branch : ''}`
        const param = {
          keyword: this.keyword,
          url: url
        }

        const result = await this.$store.dispatch('Users/search', param, { root: true })
        this.busy = false
        this.apiStatus = 'ไม่พบข้อมูล'

        if (result) {
          this.currentData = result.data;
          this.items = result.data.data  
          this.totalItem = result.data.total   
        } else {
          this.items = [] 
          this.totalItem = 0 
        }         
        
      } catch (error) {
        this.busy = false
        this.items = []
        this.apiStatus = 'ไม่พบข้อมูล'
        this.onExceptionHandler(error.response.data.message);
      }
    },
    ondelete(id) {
      this.$swal({
        width: "29rem",
        padding: '1.825em',
        html: '<div class="d-flex flex-row align-items-center">' +
          '<i class="fa fa-2x mr-3 fa-exclamation-triangle text-info"></i>'+
          '<div class="d-flex flex-column">'+
          '<span class="font-bold text-base py-2.5 text-left">คุณต้องการจะลบข้อมูลนี้หรือไม่?</span>'+
          '<span class="text-base text-left text-gray-500">หากคุณยืนยันแล้ว ข้อมูลจะไม่สามารถกู้คืนกลับมาได้?</span>'+
          '</div>'+
        '</div>',
          allowOutsideClick: false,
          focusConfirm: false,
          customClass: {
            popup: 'swal2-custom-rounded',
            closeButton: 'text-3xl swal2-custom-close',
            actions: 'justify-content-end',
            contant: 'd-flex flex-row justify-content-around px-2',
            title: 'd-none',
            confirmButton: "btn btn-info py-3 rounded-lg text-white font-prompt-light text-sm font-normal",
            cancelButton: 'btn btn-google py-3 rounded-lg text-white font-prompt-light text-sm font-normal'
          },
          cancelButtonColor: '#dc4a38',
          cancelButtonText: 'ยกเลิก',
          confirmButtonText: "ตกลง",
          showCancelButton: true,
          showCloseButton: true,
      }).then(async confirm => {
        if(confirm.value){
          this.loading = true
          this.realDelete(id)
        }
      })
    },
    handleCreateEvent(event) {
      if (event.status) {
        this.create.Id = null
        this.create.opened = false
        setTimeout(() => {
          const currentPage = this.currentPage
          this.onInitUsers(currentPage)
        }, 500)
      }
    },
    handleInfoEvent(event) {
      if (event.status) {
        this.info.opened = false
      }
    },
    async realDelete(id) {
      const param = {
        _id: id
      }
      this.loading = true
      const result = await this.$store.dispatch('Users/delete', param, { root: true })
      setTimeout(() => {
        this.loading = false
        if (result.status === 204) {
          this.$swal({
            width: "29rem",
            padding: '1.825em',
            html: '<div class="d-flex flex-row align-items-center">' +
              '<i class="fa fa-2x mr-3 fa-exclamation-triangle text-info"></i>'+
              '<div class="d-flex flex-column">'+
              '<span class="font-bold text-base py-2.5 text-left">แจ้งเตือน</span>'+
              '<span class="text-base text-left text-gray-500">ลบข้อมูลเรียบร้อยแล้ว</span>'+
              '</div>'+
            '</div>',
              allowOutsideClick: false,
              focusConfirm: false,
              customClass: {
                popup: 'swal2-custom-rounded',
                closeButton: 'text-3xl swal2-custom-close',
                actions: 'justify-content-end',
                contant: 'd-flex flex-row justify-content-around px-2',
                title: 'd-none',
                confirmButton: "btn btn-info py-3 rounded-lg text-white font-prompt-light text-sm font-normal",
                cancelButton: 'btn btn-google py-3 rounded-lg text-white font-prompt-light text-sm font-normal'
              },
              cancelButtonColor: '#dc4a38',
              cancelButtonText: 'ยกเลิก',
              confirmButtonText: "ตกลง",
              showCancelButton: false,
              showCloseButton: true,
          }).then(async confirm => {
            if(confirm.value){
              this.onInitUsers(1)
            }
          })
        } else {
          this.onExceptionHandler()
        }
      }, 500)
    },
  },
  async created(){
    await this.getRole()
    if(this.role !== 'Admin' && this.role !== 'Branch Admin'){
      this.$router.go(-1)
    }

  },
  mounted() {
    this.getBranches()
    this.onInitUsers(this.currentPage)
  }
}